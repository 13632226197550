<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="examinationModel"
    label-width="70px"
    >
    <el-form-item label="工期：" prop="periodGuid">
      <el-select v-model="examinationModel.periodGuid" clearable>
        <el-option
          v-for="period in periods"
          :key="period.periodGuid"
          :label="period.periodAlias"
          :value="period.periodGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import examinationService from '@/services/examinationService'

export default {
  name: 'ExaminationForm',
  components: {
  },
  props: {
    examinationModel: {
      type: Object,
      default () {
        return {
          periodGuid: ''
        }
      }
    }
  },
  data () {
    return {
      periods: [],
      rules: {
        periodGuid: [
          {
            required: true,
            message: '请选择工期',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getPeriods () {
      examinationService.getPeriods()
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.periods = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err.request.response)
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
        })
    }
  },
  created () {
    this._getPeriods()
  }
}
</script>
